<template>

<app-wrapper class="side">

	<h4>Share on</h4>

	<div class="share">

		<a :href="$state.page.share.linkedin" target="_blank" class="share-item"><i class="fa fa-linkedin"></i></a>
		<a :href="$state.page.share.twitter" target="_blank" class="share-item"><i class="fa fa-twitter"></i></a>
		<a :href="$state.page.share.email" target="_blank" class="share-item"><i class="fa fa-envelope"></i></a>

	</div>

	<h3>Topics</h3>

	<ul class="tags">
		<li v-for="(tag, index) in $state.page.allTags" :key="index"><router-link :to="{name: 'News', query: { topic: tag.slug }}">{{ tag.name }} <span>{{ tag.count }}</span><i class="fa-solid fa-chevron-right"></i></router-link></li>
	</ul>

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.side {
	flex-shrink: 0;
	width: 320px;
	padding: 88px 0px 64px 0px;
	margin-left: 64px;
}

.is-tablet .side {
	width: 100%;
	margin-left: 0px;
	padding: 0px 0px 40px 0px;
}

.is-mobile .side {
	width: 100%;
	margin-left: 0px;
	padding: 0px 0px 30px 0px;
}

.side h4 {
	color: #112A45;
	font-size: 14px;
	text-align: center;
	text-transform: uppercase;
	font-weight: 400;
	margin-bottom: 10px;
}

.share {
	background-color: #F5F5F5;
	display: flex;
	padding: 20px;
	justify-content: center;
	margin-bottom: 40px;
}

.share-item {
	width: 50px;
	height: 50px;
	background-color: #536F7A;
	color: #fff;
	line-height: 50px;
	text-align: center;
	font-size: 32px;
	margin: 0px 2px;
}

.is-desktop .share-item:hover {
	background-color: #374e57;
}

.side h3 {
	color: #112A45;
	font-size: 18px;
	border-bottom: 2px solid #BBD9CD;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.tags li {
	border-bottom: 1px solid #F5F5F5;
}

.is-desktop .tags li:hover {
	background-color: #BBD9CD;
}

.tags li a {
	display: block;
	font-size: 14px;
	line-height: 18px;
	padding: 8px 68px 8px 20px;
	color: #112A45;
}

.is-mobile .tags li a {
	padding: 8px 48px 8px 0px;
}

.tags li a span {
	position: absolute;
	top: 8px;
	right: 46px;
	color: #BBD9CD;
	letter-spacing: 1.4px;
}

.is-mobile .tags li a span {
	right: 26px;
}

.is-desktop .tags li:hover a span {
	color: #fff;
}

.tags li a i {
	position: absolute;
	top: 9px;
	right: 20px;
	color: #83878D;
	font-size: 14px;
}

.is-mobile .tags li a i {
	right: 0px;
}

</style>
